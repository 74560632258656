import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

import "./the-app.styles.scss";

const TheApp = () => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <div className="the-app">
      <div className="web">
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Link className="first" to="/">
              <Button className="btn">Home</Button>
            </Link>
            <Link className="link" to="/about">
              <Button className="btn">About Us</Button>
            </Link>
            <Link className="selected">
              <Button className="btn">The App</Button>
            </Link>
            <Link className="link" to="/autolink">
              <Button className="btn">AutoLink</Button>
            </Link>
            <Link className="link" to="/team">
              <Button className="btn">Team</Button>
            </Link>
            <Link className="link" to="/contact">
              <Button className="btn">Contact Us</Button>
            </Link>
          </Toolbar>
        </AppBar>

        {/* Middle Section */}
        <Grid container spacing={0} justify="center" className="body-wrapper">
          <div className="content">
            Autoloop brings to you a platform where connectivity to the owner of
            the vehicle was the team&#39;s main moto, Autoloop was created to
            solve this issue created an application which will notify you if any
            other person facing any problem related to your vehicle, your
            vehicle creates traffic or if there is any issues related to your
            vehicle in your absence.
            <br />
            <br />
            We invite you to be a part of a service that is easy to use and free
            of cost. Benefiting every Vehicle owner.
            <br />
            <br />
            <span className="title">How does it work?</span>
            <br />
            <br />
            Download The Autoloop application currently available for iOS and
            Android OS. Registration will be done through your vehicle&#39;s
            number plate using it as an unique identification number available
            in public domain.
            <img
              className="flow-image"
              alt="flow"
              src={process.env.PUBLIC_URL + "/assets/flow.png"}
            />
          </div>
        </Grid>

        {/* Footer Section */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved{" "}
          </div>
          <div className="options">
            <Link className="link-cover" to="/tnc">
              <div className="option-item">Terms & Conditions</div>
            </Link>
            <Link className="link-cover" to="/privacy">
              <div className="option-item">Privacy Policy</div>
            </Link>
          </div>
        </div>
      </div>
      <div className="mobile">
        {/* Drawer */}
        <Drawer
          className="drawer"
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <List>
            {[
              "Home",
              "About Us",
              "The App",
              "AutoLink",
              "The Team",
              "Terms & Conditions",
              "Privacy Policy",
              "Contact Us",
            ].map((text, _) => (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  onClick={() => {
                    if (text === "Home") navigate("/");
                    else if (text === "About Us") navigate("/about");
                    else if (text === "The App") navigate("/app");
                    else if (text === "AutoLink") navigate("/autolink");
                    else if (text === "The Team") navigate("/team");
                    else if (text === "Contact Us") navigate("/contact");
                    else if (text === "Terms & Conditions") navigate("/tnc");
                    else if (text === "Privacy Policy") navigate("/privacy");
                  }}
                >
                  <ListItemText
                    primary={text}
                    style={{
                      fontFamily: '"Poppins", sans-serif',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>

        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Menu className="menu-icon" onClick={() => setOpenDrawer(true)} />
          </Toolbar>
        </AppBar>

        {/* Middle Section */}
        <div className="content">
          Autoloop brings to you a platform where connectivity to the owner of
          the vehicle was the team&#39;s main moto, Autoloop was created to
          solve this issue created an application which will notify you if any
          other person facing any problem related to your vehicle, your vehicle
          creates traffic or if there is any issues related to your vehicle in
          your absence.
          <br />
          <br />
          We invite you to be a part of a service that is easy to use and free
          of cost. Benefiting every Vehicle owner.
          <br />
          <br />
          <span className="title">How does it work?</span>
          <br />
          <br />
          Download The Autoloop application currently available for iOS and
          Android OS. Registration will be done through your vehicle&#39;s
          number plate using it as an unique identification number available in
          public domain.
          <img
            className="flow-image"
            alt="flow"
            src={process.env.PUBLIC_URL + "/assets/flow.png"}
          />
          <ul className="list">
            <li>Tap on search icon</li>
            <li>Enter the Vehicle Number.</li>
            <li>
              User Profile will be appear, if the vehicle is registered with
              Autoloop
            </li>
            <li>
              Then you can easily notify the owner through sending alerts or
              custom messages.
            </li>
          </ul>
        </div>

        {/* Footer */}
        <div className="footer">
          Abiomye Industries Pvt. Ltd. | All rights reserved
        </div>
      </div>
    </div>
  );
};

export default TheApp;
