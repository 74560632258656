import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Landing from "./pages/landing/landing.component";
import AboutUs from "./pages/about-us/about-us.component";
import TnC from "./pages/tnc/tnc.component";
import PrivacyPolicy from "./pages/privacy/privacy.component";
import TheApp from "./pages/the-app/the-app.component";
import LinkX from "./pages/linkx/linkx.component";
import Contact from "./pages/contact/contact.component";
import Team from "./pages/team/team.component";

import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/app" element={<TheApp />} />
        <Route path="/autolink" element={<LinkX />} />
        <Route path="/tnc" element={<TnC />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/team" element={<Team />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
