import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Menu } from "@mui/icons-material";
import StoreBadge from "react-store-badge";

import "./about-us.styles.scss";

const AboutUs = () => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <div className="about-us">
      <div className="web">
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Link className="first" to="/">
              <Button className="btn">Home</Button>
            </Link>
            <Link className="selected">
              <Button className="btn">About Us</Button>
            </Link>
            <Link className="link" to="/app">
              <Button className="btn">The App</Button>
            </Link>
            <Link className="link" to="/autolink">
              <Button className="btn">AutoLink</Button>
            </Link>
            <Link className="link" to="/team">
              <Button className="btn">Team</Button>
            </Link>
            <Link className="link" to="/contact">
              <Button className="btn">Contact Us</Button>
            </Link>
          </Toolbar>
        </AppBar>

        {/* Middle Section */}
        <Grid container spacing={0} justify="center">
          {/* Left Section */}
          <Grid item xs={7} className="left-item">
            <div className="left-content">
              <div className="title">About US</div>
              <div className="body">
                Autoloop vehicle communication systems.
                <br />
                <br />
                Focused on vehicle communication systems through vehicle I.D. a
                solution to daily street chaos. Having the main objectives of
                being “Free of Cost” that could be used by all.
                <br />
                <br />
                Vehicles as per Autoloop are any moveable objects having a
                registered number plate. The Number plate which is unique and a
                part of the public domain gives the owner the personal identity
                in our vehicle communication system, now available in iOS and
                Android operating systems.
              </div>
              <StoreBadge
                className="download-options"
                name="Autoloop"
                googlePlayUrl="https://play.google.com/store/apps/details?id=com.autoloop.android"
                appStoreUrl="https://apps.apple.com/app/autoloop/id6443796015"
              />
            </div>
          </Grid>
          {/* Right Section */}
          <Grid item xs={5} className="right-item">
            <div>
              <img
                className="app-image"
                alt="mockup"
                src={process.env.PUBLIC_URL + "/assets/about.png"}
              />
            </div>
          </Grid>
        </Grid>

        {/* Footer Section */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved{" "}
          </div>
          <div className="options">
            <Link className="link-cover" to="/tnc">
              <div className="option-item">Terms & Conditions</div>
            </Link>
            <Link className="link-cover" to="/privacy">
              <div className="option-item">Privacy Policy</div>
            </Link>
          </div>
        </div>
      </div>
      <div className="mobile">
        {/* Drawer */}
        <Drawer
          className="drawer"
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <List>
            {[
              "Home",
              "About Us",
              "The App",
              "AutoLink",
              "The Team",
              "Terms & Conditions",
              "Privacy Policy",
              "Contact Us",
            ].map((text, _) => (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  onClick={() => {
                    if (text === "Home") navigate("/");
                    else if (text === "About Us") navigate("/about");
                    else if (text === "The App") navigate("/app");
                    else if (text === "AutoLink") navigate("/autolink");
                    else if (text === "The Team") navigate("/team");
                    else if (text === "Contact Us") navigate("/contact");
                    else if (text === "Terms & Conditions") navigate("/tnc");
                    else if (text === "Privacy Policy") navigate("/privacy");
                  }}
                >
                  <ListItemText
                    primary={text}
                    style={{
                      fontFamily: '"Poppins", sans-serif',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>

        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Menu className="menu-icon" onClick={() => setOpenDrawer(true)} />
          </Toolbar>
        </AppBar>

        {/* Middle Section */}
        <div className="content">
          <div className="title">About US</div>
          <StoreBadge
            className="download-options"
            name="Autoloop"
            googlePlayUrl="https://play.google.com/store/apps/details?id=com.autoloop.android"
            appStoreUrl="https://apps.apple.com/app/autoloop/id6443796015"
          />
          <img
            className="app-image"
            alt="mockup"
            src={process.env.PUBLIC_URL + "/assets/about.png"}
          />
          <div className="body">
            Autoloop vehicle communication systems.
            <br />
            <br />
            Focused on vehicle communication systems through vehicle I.D. a
            solution to daily street chaos. Having the main objectives of being
            “Free of Cost” that could be used by all.
            <br />
            <br />
            Vehicles as per Autoloop are any moveable objects having a
            registered number plate. The Number plate which is unique and a part
            of the public domain gives the owner the personal identity in our
            vehicle communication system, now available in iOS and Android
            operating systems.
          </div>
        </div>

        {/* Footer */}
        <div className="footer">
          Abiomye Industries Pvt. Ltd. | All rights reserved
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
